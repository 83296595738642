export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/app/chat": [17,[6]],
		"/app/dev": [18,[6]],
		"/app/documents": [19,[6]],
		"/app/documents/edit": [20,[6]],
		"/app/notes": [21,[6]],
		"/app/search": [22,[6]],
		"/app/settings": [23,[6]],
		"/beta/congresdesnotaires": [24,[7]],
		"/[lang]": [8,[2]],
		"/[lang]/docs": [~9,[2,3]],
		"/[lang]/docs/[slug]": [10,[2,3]],
		"/[lang]/invest": [11,[2]],
		"/[lang]/join": [12,[2]],
		"/[lang]/legal/[slug]": [13,[2,4]],
		"/[lang]/news": [14,[2,5]],
		"/[lang]/news/[slug]": [15,[2,5]],
		"/[lang]/pricing": [16,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';